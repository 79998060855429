/* istanbul ignore file */

import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { deepmerge } from 'deepmerge-ts';

export class MultiTranslateHttpLoader implements TranslateLoader {

  public getTranslation(): Observable<unknown> {
    const catchErr = catchError(res => {
      console.error('Something went wrong for the following translation file');
      console.error(res.message);
      return of({});
    });
    return forkJoin([
      from(import('../../../../assets/i18n/common.en.json')).pipe(map(a => a.default), catchErr),
      from(import('../../../../assets/i18n/markets.en.json')).pipe(map(a => a.default), catchErr),
    ]).pipe(map(response => deepmerge(...response)));
  }

}
