import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Faq, SmartBannerConfig } from '@scpc/dto';
import { catchError, map } from 'rxjs/operators';
import { Settings } from '@scpc/dto/settings';
import { PageData } from '../resolvers/page.resolver';
import { Contacts } from '@scpc/modules/contacts/contacts';
import { LiveGame } from '@scpc/dto/live-game';
import { Banner } from '@scpc/modules/common/components/banners/models';
import { LuckyNumbersSettings } from '@scpc/dto/lucky-numbers-settings';
import { CacheService } from '@scpc/modules/common/services/cache.service';
import { minutesToMilliseconds, secondsToMilliseconds } from '@scpc/utils/date.utils';
import { SlotGame } from '@scpc/dto/slot-game';
import { Game, GamesCategory } from '@scpc/modules/games-lobby/model';
import { LobbyItem } from '@scpc/dto/lobby';
import { StorageService } from '@scpc/modules/common/services/storage.service';
import { PromotionLobby } from '@scpc/dto/promotion';
import { TopWinners } from '@scpc/dto/top-winner';
import { Apps } from '@scpc/modules/apps/apps';

export interface Document {
  content: string;
  toc: false | {
    href: string;
    isSecondary: boolean;
    level: string;
    title: string;
  };
}

export interface ApplePaySettings {
  version: number;
  url: string;
  merchantIdentifier: string;
  countryCode: string;
  merchantCapabilities: string[],
  supportedNetworks: string[],
  currencyCode: string[],
  supportedCountries: string[]
}

export interface CMSDepositsSettings {
  types: {
    type: string,
    label?: string,
    bonus: boolean;
  }[];
}


export interface Country {
  code: string;
  name: string;
}

export interface Bookmaker {
  name: string;
  logo: {
    url: string;
    width: number;
    height: number;
  };
  advantage1: string;
  advantage2: string;
  advantage3: string;
  button: string;
  offer: string;
  url: string;
}

export interface Bookmakers {
  country: Country;
  bookmakers: Bookmaker[];
}

@Injectable({ providedIn: 'root' })
export class CmsService {
  private cache: Map<string, any> = new Map<string, any>();

  constructor(private readonly http: HttpClient,
              private readonly cacheService: CacheService,
              private readonly storageService: StorageService) {
  }

  public getSettings(): Observable<Settings> {
    const path = '/cms/api/setting?populate[0]=products&' +
      'populate[1]=products.product&' +
      'populate[2]=meta&' +
      'populate[3]=snippets&' +
      'populate[4]=reviews&' +
      'populate[5]=products.icon&' +
      'populate[6]=products.product.icon&' +
      'populate[7]=supportChats';
    return this.getFromCache(path).pipe(map((response: { data: Settings }) => response.data));
  }

  public getPage(path: string): Observable<PageData[]> {
    return this.getFromCache('/cms/api/pages?populate[0]=seo&filters[path][$eq]=' + path)
      .pipe(map((response: { data: { seoText?: string, seo: { metaTitle: string, metaDescription: string } }[] }) => {
        if (response.data.length) {
          return [{
            title: response.data[0].seo.metaTitle,
            description: response.data[0].seo.metaDescription,
            seoText: response.data[0].seoText,
            banners: [],
          }];
        }
        return [];
      }));
  }

  public getTopWinners(): Observable<TopWinners> {
    return this.http.get<TopWinners>('/cms/api/games/top-winners');
  }

  public getTerms(): Observable<Document> {
    return this.getDocument('TERMS_AND_CONDITIONS');
  }

  public getAboutUs(): Observable<Document> {
    return this.getDocument('ABOUT_US');
  }

  public getPoPIA(): Observable<Document> {
    return this.getDocument('POPIA');
  }

  public getResponsibleGambling(): Observable<Document> {
    return this.getDocument('RESPONSIBLE_GAMBLING');
  }

  public getRegulations(): Observable<Document> {
    return this.getDocument('REGULATIONS');
  }

  public getPrivacyPolicy(): Observable<Document> {
    return this.getDocument('PRIVACY_POLICY');
  }

  public getRules(): Observable<Document> {
    return this.getDocument('RULES');
  }

  public getApplePaySettings(): Observable<ApplePaySettings> {
    return this.http.get<{ data: ApplePaySettings }>('/cms/api/apple-pay')
      .pipe(map((response: { data: ApplePaySettings }) => response.data));
  }

  public getDepositsSettings(): Observable<CMSDepositsSettings> {
    return this.http.get<{ data: CMSDepositsSettings }>('/cms/api/setting-deposits', {
      headers: new HttpHeaders({
        'X-Authorization': `Bearer ${this.storageService.accessToken}`,
      }),
    }).pipe(map((response: { data: CMSDepositsSettings }) => response.data));
  }

  public getLuckyNumbersSettings(): Observable<LuckyNumbersSettings> {
    return this.getFromCache('/cms/api/setting-lucky-numbers')
      .pipe(map((response: { data: LuckyNumbersSettings }) => response.data));
  }

  public getContacts(): Observable<Contacts> {
    return this.http.get<{ data: Contacts }>('/cms/api/contact').pipe(map((response: {
      data: Contacts
    }) => response.data));
  }

  public getApps(): Observable<Apps> {
    return this.getFromCache('/cms/api/setting-mobile-application?populate[0]=desktop&populate[1]=mobile&populate[2]=qr')
      .pipe(map((response: { data: Apps }) => response.data));
  }

  public getBookmakers(): Observable<Bookmakers> {
    return this.http.get<Bookmakers>('/cms/api/bookmakers/recommendations');
  }

  public getFrequentlyAskedQuestionsCategories(): Observable<Faq[]> {
    return this.http.get<{ data: Faq[] }>('/cms/api/frequently-asked-questions?sort=rank&populate=icon')
      .pipe(map((response: { data: Faq[] }) => response.data));
  }

  public getFrequentlyAskedQuestionsCategory(path: string): Observable<Faq[]> {
    return this.http.get<{
      data: Faq[]
    }>('/cms/api/frequently-asked-questions?sort=rank&populate[0]=questions&filters[slug][$eq]=' + path)
      .pipe(map((response: { data: Faq[] }) => response.data));
  }

  public getNotifications(): Observable<{ type: string; enabled: boolean }[]> {
    return this.http.get<{ data: { types: { type: string; enabled: boolean; }[] } }>('/cms/api/notification?populate=*')
      .pipe(map((response: { data: { types: { type: string; enabled: boolean; }[] } }) => response.data.types));
  }

  public getLobby(): Observable<LobbyItem[]> {
    return this.fetchLobby('/cms/api/lobby');
  }

  public getPromotionLobby(): Observable<PromotionLobby> {
    return this.http.get<PromotionLobby>('/cms/api/promotions-lobby', this.storageService.accessToken ? {
      headers: new HttpHeaders({
        'X-Authorization': `Bearer ${this.storageService.accessToken}`,
      }),
    } : undefined);
  }

  public getSlotGamesLobby(): Observable<LobbyItem[]> {
    const path: string = '/cms/api/slot-games-lobby/layout';
    return this.cacheService.get(path, this.fetchLobby(path), minutesToMilliseconds(5))
      .pipe(map((data: LobbyItem[]) => data.map((item: LobbyItem) => Object.create(item))));
  }

  public getLiveGamesLobby(): Observable<LobbyItem[]> {
    const path: string = '/cms/api/live-games-lobby/layout';
    return this.cacheService.get(path, this.fetchLobby(path), minutesToMilliseconds(5))
      .pipe(map((data: LobbyItem[]) => data.map((item: LobbyItem) => Object.create(item))));
  }

  public getLuckyNumbersLobby(): Observable<LobbyItem[]> {
    return this.fetchLobby('/cms/api/lucky-numbers-lobby/layout');
  }

  public getSportsLobby(): Observable<LobbyItem[]> {
    return this.fetchLobby('/cms/api/sports-lobby/layout');
  }

  public getLiveGame(gameId: string, recommended: boolean = false): Observable<LiveGame> {
    return this.http.get<LiveGame>(`/cms/api/live-games-lobby/games/${gameId}?recommended=${recommended}`);
  }

  public getSlotGame(gameId: string, recommended: boolean = false): Observable<SlotGame> {
    return this.http.get<SlotGame>(`/cms/api/slot-games-lobby/games/${gameId}?recommended=${recommended}`);
  }

  public getBanners(product: string): Observable<Banner[]> {
    const path: string = `/cms/api/banners/published?product=${product}`;
    return this.cacheService.get(path, this.http.get<Banner[]>(path), minutesToMilliseconds(1));
  }

  public getLiveGamesCategory(slug: string): Observable<GamesCategory<LiveGame>[]> {
    const path: string = `/cms/api/live-games-lobby/categories?category=${slug}`;
    return this.cacheService.get(path, this.http.get<GamesCategory<LiveGame>[]>(path), secondsToMilliseconds(5))
      .pipe(map((data: GamesCategory<LiveGame>[][]) => data.map((category: GamesCategory<LiveGame>[]) => Object.create(category))));
  }

  public hasLiveGamesCategoryInTheCache(slug: string): boolean {
    const path: string = `/cms/api/live-games-lobby/categories?category=${slug}`;
    return this.cacheService.has(path);
  }

  public getSlotGamesCategory(slug: string): Observable<GamesCategory<SlotGame>[]> {
    const path: string = `/cms/api/slot-games-lobby/categories?category=${slug}`;
    return this.cacheService.get(path, this.http.get<GamesCategory<SlotGame>[]>(path), secondsToMilliseconds(5))
      .pipe(map((data: GamesCategory<SlotGame>[]) => data.map((category: GamesCategory<SlotGame>) => Object.create(category))));
  }

  public hasSlotGamesCategoryInTheCache(slug: string): boolean {
    const path: string = `/cms/api/slot-games-lobby/categories?category=${slug}`;
    return this.cacheService.has(path);
  }

  public getRecentlyPlayedGames(customerId: string, product: string, category: string): Observable<Game[]> {
    return this.http.get<Game[]>(`/cms/api/games/recently-played?customerId=${customerId}&product=${product}&category=${category}`);
  }

  public getSmartBanner(): Observable<SmartBannerConfig | null> {
    return this.http.get<SmartBannerConfig>('/cms/api/setting-app-smart-banner')
      .pipe(catchError(/* istanbul ignore next */() => of(null)));
  }

  private getDocument(type: string): Observable<Document> {
    return this.http.get<{ data: Document[] }>
    ('/cms/api/documents?fields[0]=content&fields[1]=toc&pagination[withCount]=false&pagination[page]=1&pagination[pageSize]=1&filters[type][$eq]=' + type)
      .pipe(map((response: { data: Document[] }) => response.data[0]));
  }

  private getFromCache<T>(path): Observable<T> {
    if (this.cache.has(path)) {
      return of(this.cache.get(path));
    }
    return this.http.get<T>(path).pipe(
      map((data) => {
        this.cache.set(path, data);
        return data;
      }),
    );
  }

  private fetchLobby(path: string): Observable<LobbyItem[]> {
    return this.http.get<LobbyItem[]>(path, this.storageService.accessToken ? {
      headers: new HttpHeaders({
        'X-Authorization': `Bearer ${this.storageService.accessToken}`,
      }),
    } : undefined);
  }

}
