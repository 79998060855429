export const REDIRECTS_301: [string, string][] = [
  ...[
    ['play-nuwa-online', 'nuwa'],
    ['fruit-shop-slot-win-big', 'fruit-shop'],
    ['gonzo-quest-slot', 'gonzos-quest'],
    ['power-mighty-medusa-slot', 'mighty-medusa'],
    ['wealth-inn-slot', 'wealth-inn'],
    ['wild-trucks-slot-by-habanero', 'wild-trucks'],
    ['win-big-with-cash-ultimate-slot', 'cash-ultimate'],
    ['wings-of-ra-slot', 'wings-of-ra'],
    ['rolling-roger-slot', 'rolling-roger'],
    ['hot-hot-fruit-slot', 'hot-hot-fruit'],
    ['10001-nights-slot', '10001-nights'],
    ['lantern-luck-slot', 'lantern-luck'],
    ['hey-sushi-slot', 'hey-sushi'],
    ['lucky-durian-slot', 'lucky-durian'],
    ['777-strike-slot', '777-strike'],
    ['cash-volt-slot', 'cash-volt'],
    ['disco-beats-slot', 'disco-beats'],
    ['piggy-riches-slot', 'piggy-riches'],
    ['egyptian-dreams-deluxe-slot', 'egyptian-dreams'],
    ['5-lions-megaways-slot', '5-lions-megaways'],
    ['888-dragons-slot', '888-dragons'],
    ['big-bass-splash-slot', 'big-bass-splash'],
    ['book-of-fallen-slot', 'book-of-fallen'],
    ['buffalo-king-megaways-slot', 'buffalo-king-megaways'],
    ['cleocatra-slot', 'cleocatra'],
    ['cowboys-gold-slot', 'cowboys-gold'],
    ['diamond-strike-slot', 'diamond-strike'],
    ['floating-dragon-hold-and-spin-slot', 'floating-dragon-hold-and-spin'],
    ['bigger-bass-bonanza-slot', 'big-bass-bonanza'],
    ['dynamite-riches-slot', 'dynamite-riches'],
    ['starburst-xx-xtreme', 'starburst-xxxtreme'],
    ['blood-suckers-slot', 'blood-suckers'],
    ['mystic-fortune-deluxe-demo-slot-game', 'mystic-fortune-deluxe'],
    ['sparkling-fun-starburst-slot', 'starburst'],
  ].map((item: [string, string]): [string, string] => [`/blog/posts/${item[0]}`, `/slots/${item[1]}`]),
];
