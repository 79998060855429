/* istanbul ignore file */

import { ApplicationRef, inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { GoogleTagManagerConfig } from '@scpc/modules/common/services/analytics/google-tag-manager.config';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { DOCUMENT, isPlatformBrowser, TitleCasePipe } from '@angular/common';
import { LuckyNumbersWager } from '@scpc/modules/lucky-numbers/dto/lucky-numbers.wager';
import { formatMoney } from '@scpc/utils/money.utils';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { DrawResult } from '@scpc/modules/lucky-numbers/dto/draw-result';
import { Promotion } from '@scpc/dto/promotion';
import { Money, Subscription } from '@scpc/dto';
import { BigoService } from '@scpc/modules/common/services/analytics/bigo.service';
import { Event } from '@scpc/modules/sports/dto';
import { SportCart, SportCartSelection, SportCartSelectionId } from '@scpc/modules/cart/model';
import { first } from 'rxjs/operators';
import { SportRadarService } from '@scpc/modules/common/services/analytics/sportradar.service';
import { AnalyticsData, AnalyticsService } from '@scpc/modules/common/services/analytics/analytics.service';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {

  private isLoaded: boolean = false;
  private config: GoogleTagManagerConfig;
  private titleCasePipe: TitleCasePipe = new TitleCasePipe();
  private configService: ConfigService = inject(ConfigService);
  private zone: NgZone = inject(NgZone);
  private appRef: ApplicationRef = inject(ApplicationRef);
  private document: Document = inject(DOCUMENT);
  private isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  private bigoService: BigoService = inject(BigoService);
  private sportRadarService: SportRadarService = inject(SportRadarService);
  private analytics: AnalyticsService<unknown>[] = [this.bigoService, this.sportRadarService];

  private browserGlobals = {
    windowRef(): any {
      return window;
    },
  };

  constructor() {
    if (this.isBrowser) {
      this.browserGlobals.windowRef().dataLayer = this.getDataLayer() || [];
    }
    this.configService.googleTagManagerId.subscribe((id: string): void => {
      if (id) {
        this.config = { id };
      }
    });
  }

  public addGtmToDom(): void {
    if (this.isLoaded || !this.config) {
      return;
    }
    if (this.isBrowser) {
      this.pushOnDataLayer({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      if (!this.document.getElementById('GTMscript')) {
        this.appRef.isStable.pipe(first((isStable: boolean) => isStable === true));
        this.zone.runOutsideAngular((): void => {
          const gtmScript: HTMLScriptElement = this.document.createElement('script');
          gtmScript.id = 'GTMscript';
          gtmScript.setAttribute('async', '');
          gtmScript.src = this.applyGtmQueryParams('https://www.googletagmanager.com/gtm.js');
          this.document.getElementsByTagName('scp-app')[0].append(gtmScript);
        });
      }
    }
    this.isLoaded = true;
  }

  public addGtmToDomIfNotLoadedAndGetDataLayer() {
    if (!this.isLoaded) {
      this.addGtmToDom();
    }
    return this.getDataLayer();
  }

  public event(category: string, action: string, label?: string, value?: string, data?: AnalyticsData): void {
    if (!this.isBrowser) {
      return;
    }
    const dataLayer = this.addGtmToDomIfNotLoadedAndGetDataLayer();
    dataLayer.push({
      ecommerce: undefined,
      category: undefined,
      action: undefined,
      label: undefined,
      value: undefined,
    });
    dataLayer.push({ event: 'scp_event', category, action, label, value });
    this.analytics.forEach((analytic: AnalyticsService<unknown>): void => analytic.event(category, action, label, value, data));
  }

  public viewDrawsItemList(items: Draw[] | DrawResult[], source: string): void {
    this.sendEcommerceEvent('view_item_list', {
      item_list_name: source,
      items: items.map(item => this.drawToItem(item, source)),
    });
  }

  public viewPromotion(item: Promotion, source?: string): void {
    this.sendEcommerceEvent('view_promotion', { items: [this.promotionToItem(item, source)] });
  }

  public selectDrawItem(item: Draw | DrawResult, source: string): void {
    this.sendEcommerceEvent('select_item', { items: [this.drawToItem(item, source)] });
  }

  public selectPromotion(item: Promotion, source?: string): void {
    this.sendEcommerceEvent('select_promotion', { items: [this.promotionToItem(item, source)] });
  }

  public viewDrawItem(draw: Draw, source?: string): void {
    this.sendEcommerceEvent('view_item', { items: [this.drawToItem(draw)] });
    if (source === 'draw-page') {
      this.sportRadarService.event('lucky-numbers', 'page_view', undefined, undefined, { draw } as AnalyticsData);
    }
  }

  public addToLuckyNumbersCart(wagers: LuckyNumbersWager[], draw: Draw): void {
    for (const wager of wagers) {
      this.sendEcommerceEvent('add_to_cart', { items: [this.luckyNumbersWagerToItem(wager)] });
      if (draw) {
        this.sportRadarService.event('lucky-numbers', 'launch', undefined, undefined, { draw } as AnalyticsData);
      }
    }
  }

  public removeFromLuckyNumbersCart(wagers: LuckyNumbersWager[]): void {
    for (const wager of wagers) {
      this.sendEcommerceEvent('remove_from_cart', { items: [this.luckyNumbersWagerToItem(wager)] });
    }
  }

  public viewLuckyNumbersCart(wagers: LuckyNumbersWager[]): void {
    this.sendEcommerceEvent('view_cart', { items: wagers.map((wager: LuckyNumbersWager) => this.luckyNumbersWagerToItem(wager)) });
  }

  public purchaseLuckyNumbersWagers(wagers: LuckyNumbersWager[]): void {
    const dataLayer = this.addGtmToDomIfNotLoadedAndGetDataLayer();
    for (const wager of wagers) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: wager.id,
          value: formatMoney({
            value: wager.stake.value * wager.selection.numberOfBets,
            currency: wager.stake.currency,
          }, false, true),
          tax: '0.00',
          shipping: '0.00',
          currency: wager.stake.currency,
          promotion_id: wager.promotionCode,
          promotion_name: wager.promotionName,
          items: [{
            item_id: wager.selection.drawId,
            item_name: wager.selection.drawName,
            item_category: 'Lucky Numbers',
            item_category2: wager.selection.country,
            item_category3: wager.selection.drawType,
            price: formatMoney(wager.stake, false, true),
            discount: wager.promotionDiscount ? formatMoney({
              // eslint-disable-next-line no-bitwise
              value: (wager.promotionDiscount.value / wager.selection.numberOfBets | 0),
              currency: wager.stake.currency,
            }, false, true) : undefined,
            quantity: wager.selection.numberOfBets,
            item_list_name: wager.source || undefined,
          }],
        },
      });
      this.sportRadarService.event('cart', 'bet_placed', undefined, undefined, {
        purchase: {
          transactionId: wager.id,
          amount: formatMoney(wager.stake, false, true),
          currency: wager.stake.currency,
          contentIds: [wager.selection.drawId],
        },
      } as AnalyticsData);
      if (wager.subscriptionId && wager.subscription) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: wager.subscriptionId,
            value: formatMoney({
              value: wager.stake.value * wager.selection.numberOfBets * (wager.subscription.times - 1),
              currency: wager.stake.currency,
            }, false, true),
            tax: '0.00',
            shipping: '0.00',
            currency: wager.stake.currency,
            items: new Array(wager.subscription.times - 1).fill(null).map(() => ({
              item_id: wager.selection.drawId + '_subscription',
              item_name: wager.selection.drawName + ' (SUBSCRIPTION)',
              item_category: 'Lucky Numbers',
              item_category2: wager.selection.country,
              item_category3: wager.selection.drawType,
              price: formatMoney(wager.stake, false, true),
              quantity: wager.selection.numberOfBets,
            })),
          },
        });
        this.sportRadarService.event('cart', 'bet_placed', undefined, undefined, {
          purchase: {
            transactionId: wager.subscriptionId,
            amount: formatMoney({
              value: wager.stake.value * wager.selection.numberOfBets * (wager.subscription.times - 1),
              currency: wager.stake.currency,
            }, false, true),
            currency: wager.stake.currency,
            contentIds: [wager.selection.drawId],
          },
        } as AnalyticsData);
      }
    }
  }

  public viewEventsItemList(events: Event[], source: string): void {
    this.sendEcommerceEvent('view_item_list', {
      item_list_name: source,
      items: events.map((item: Event) => this.eventToItem(item, source)),
    });
  }

  public selectSportItem(event: Event, source: string): void {
    this.sendEcommerceEvent('select_item', { items: [this.eventToItem(event, source)] });
  }

  public viewSportItem(event: Event, source: string): void {
    this.sendEcommerceEvent('view_item', { items: [this.eventToItem(event, source)] });
    this.sportRadarService.event('sports', 'page_view', undefined, undefined, { event } as AnalyticsData);
  }

  public addToSportCart(event: Event | SportCartSelection[], source: string, selection?: SportCartSelectionId): void {
    if (Array.isArray(event)) {
      this.sendEcommerceEvent('add_to_cart', {
        items: event.map((selection: SportCartSelection) => this.sportCartSelectionToItem(selection, source)),
      });
    } else {
      this.sendEcommerceEvent('add_to_cart', { items: [this.eventToItem(event, source)] });
      this.sportRadarService.event('sports', 'add_to_cart', undefined, undefined, {
        event,
        selection,
      } as AnalyticsData);
    }
  }

  public removeFromSportCart(events: Event | SportCartSelection[], source: string): void {
    if (Array.isArray(events)) {
      this.sendEcommerceEvent('remove_from_cart', {
        items: events.map((selection: SportCartSelection) => this.sportCartSelectionToItem(selection)),
      });
    } else {
      this.sendEcommerceEvent('remove_from_cart', { items: [this.eventToItem(events, source)] });
    }
  }

  public viewSportCart(cart: SportCart): void {
    this.sendEcommerceEvent('view_cart', { items: cart.selections.map((selection: SportCartSelection) => this.sportCartSelectionToItem(selection)) });
  }

  public purchaseSportsWagers(cart: SportCart): void {
    if (cart.type === 'SINGLE') {
      const selections: SportCartSelection[] = cart.selections.filter((selection: SportCartSelection): boolean => 'ACCEPTED' === selection.status);
      if (selections.length > 0) {
        // eslint-disable-next-line no-underscore-dangle
        this._purchaseSportsWagers(selections.map((selection: SportCartSelection) => ({
          wagerId: selection.wagerId,
          stake: selection.stakeAsMoney,
          selections: [selection],
        })));
      }
    } else if (cart.type === 'MULTI' && cart.status === 'ACCEPTED') {
      // eslint-disable-next-line no-underscore-dangle
      this._purchaseSportsWagers([{
        wagerId: cart.wagerId,
        stake: cart.totalStakeAsMoney,
        selections: cart.selections.map((selection: SportCartSelection): SportCartSelection => ({
          ...selection,
          stakeAsMoney: {
            currency: cart.totalStakeAsMoney.currency,
            value: Math.round(cart.totalStakeAsMoney.value / cart.selections.length),
          },
        })),
      }]);
    }
  }

  public refund(wagerId: string, amount: Money): void {
    this.sendEcommerceEvent('refund', {
      transaction_id: wagerId,
      value: formatMoney(amount, false, true),
      currency: amount.currency,
    });
  }

  public refundSubscription(subscription: Subscription): void {
    this.sendEcommerceEvent('refund', {
      transaction_id: subscription.id,
      value: formatMoney({
        currency: subscription.stake.currency,
        value: subscription.stake.value * subscription.remaining * subscription.numberOfBets,
      }, false, true),
      currency: subscription.stake.currency,
      items: [{
        item_id: subscription.event.id + '_subscription',
        item_name: subscription.event.name + ' (SUBSCRIPTION)',
        item_category: 'Lucky Numbers',
        item_category2: subscription.event.country,
        item_category3: subscription.event.type,
        price: formatMoney(subscription.stake, false, true),
        quantity: subscription.numberOfBets,
      }],
    });
  }

  private sendEcommerceEvent(event: string, ecommerce: {
    transaction_id?: string,
    item_list_name?: string,
    value?: string,
    currency?: string
    items?: {
      item_id: string,
      item_name: string,
      item_category: string,
      item_category2?: string,
      item_category3?: string,
      item_list_name?: string
      promotion_id?: string,
      promotion_name?: string,
      price?: string,
      quantity?: number
      discount?: string
    }[]
  }): void {
    const dataLayer = this.addGtmToDomIfNotLoadedAndGetDataLayer();
    dataLayer.push({
      ecommerce: undefined,
      category: undefined,
      action: undefined,
      label: undefined,
      value: undefined,
    });
    dataLayer.push({ event, ecommerce });
  }

  private drawToItem(item: Draw | DrawResult, source?: string) {
    return {
      item_id: item.drawId,
      item_name: item.drawName,
      item_category: 'Lucky Numbers',
      item_category2: item.country,
      item_category3: item.type,
      item_list_name: source || undefined,
    };
  }

  private eventToItem(item: Event, source: string) {
    return {
      item_id: item.id,
      item_name: item.season ? item.season.name : `${item.homeTeam.name} - ${item.awayTeam.name}`,
      item_category: 'Sports',
      item_category2: item.sport.name,
      item_category3: `${item.sport.name}. ${item.category.name}. ${item.tournament.name}`,
      item_category4: item.live ? 'Live' : 'Prematch',
      item_list_name: source || undefined,
      quantity: 1,
    };
  }

  private sportCartSelectionToItem(item: SportCartSelection, source?: string) {
    return {
      item_id: item.eventId,
      item_name: item.season ? item.season.name : `${item.homeTeam.name} - ${item.awayTeam.name}`,
      item_category: 'Sports',
      item_category2: item.sportName,
      item_category3: `${item.sportName}. ${item.categoryName}. ${item.tournamentName}`,
      item_category4: item.live ? 'Live' : 'Prematch',
      item_list_name: source || item.source || undefined,
      quantity: 1,
    };
  }

  private promotionToItem(item: Promotion, source?: string) {
    return {
      item_id: item.events[0].eventId.trim(),
      item_name: item.events[0].eventName.trim(),
      item_category: 'Lucky Numbers',
      item_category2: this.drawNameToCountry(item.events[0].eventName),
      //  item_category3: item.type,
      item_list_name: source || undefined,
      promotion_id: item.code,
      promotion_name: item.name,
      discount: formatMoney({
        value: (item.discount.value / item.events[0].combinations),
        currency: item.discount.currency,
      }, false, true),
      quantity: item.events[0].combinations,
    };
  }

  private luckyNumbersWagerToItem(wager: LuckyNumbersWager) {
    return {
      item_id: wager.selection.drawId,
      item_name: wager.selection.drawName,
      item_category: 'Lucky Numbers',
      item_category2: wager.selection.country,
      item_category3: wager.selection.drawType,
      promotion_id: wager.promotionCode,
      promotion_name: wager.promotionName,
      quantity: wager.selection.numberOfBets,
      item_list_name: wager.source || undefined,
    };
  }

  private drawNameToCountry(name: string): string {
    let n: string = name.substring(0, name.indexOf('-')).trim();
    switch (n) {
      case 'NZL':
        n = 'New Zealand';
        break;
      case 'RSA':
        n = 'South Africa';
        break;
    }
    return this.titleCasePipe.transform(n).replace(' And ', 'and');
  }

  private getDataLayer() {
    if (this.isBrowser) {
      return this.browserGlobals.windowRef().dataLayer || [];
    }
    return [];
  }

  private pushOnDataLayer(obj: Record<string, unknown>): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }

  private _purchaseSportsWagers(wagers: { wagerId: string, stake: Money, selections: SportCartSelection[] }[]): void {
    const dataLayer = this.addGtmToDomIfNotLoadedAndGetDataLayer();
    for (const wager of wagers) {
      dataLayer.push({ ecommerce: null });
      const ecommerce = {
        transaction_id: wager.wagerId,
        value: formatMoney(wager.stake, false, true),
        tax: '0.00',
        shipping: '0.00',
        currency: wager.stake.currency,
        items: wager.selections.map((selection: SportCartSelection) => ({
          ...this.sportCartSelectionToItem(selection),
          price: formatMoney(selection.stakeAsMoney, false, true),
        })),
      };
      dataLayer.push({ event: 'purchase', ecommerce });
      this.sportRadarService.event('cart', 'bet_placed', undefined, undefined, {
        purchase: {
          transactionId: wager.wagerId,
          amount: formatMoney(wager.stake, false, true),
          currency: wager.stake.currency,
          contentIds: wager.selections.map((selection: SportCartSelection): string => selection.eventId),
        },
      } as AnalyticsData);
    }
  }

}
