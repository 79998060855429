import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import {
  AccessToken,
  Balance,
  BanksDepositSettings,
  BankVerification,
  BusinessTransaction,
  Card,
  CardTransaction,
  CreateCardRequest,
  DepositConfiguration,
  DepositLimit,
  DepositType,
  DirectCardTransaction,
  FullCardDetails,
  InboxMessage,
  KycSettings,
  Money,
  OperatorBank,
  OzowTransaction,
  Page,
  SimpleUserAccount,
  SnapScanTransaction,
  Subscription,
  UsernameStatus,
  Voucher,
  Wager,
  WagerType,
  WithdrawalConfiguration,
  WithdrawalFee,
  WithdrawalRequest,
  WithdrawalStatistics,
  ZapperTransaction,
} from '@scpc/dto';
import { Customer, CustomerBank, GoogleAnalytics, Profile, Referrer } from '@scpc/dto/customer';
import { StorageService } from './storage.service';
import {
  BonusProgramCategory,
  BonusProgramOffer,
  BonusProgramOffersStats,
  BonusProgramOfferStatus,
  DepositBonusProgramOffer,
  Referral,
} from '@scpc/dto/bonus-program-offer';
import { DepositResult } from '@scpc/dto/deposit.result';
import { CreditTransferToken } from '@scpc/dto/credit-transfer-token';
import { map, switchMap } from 'rxjs/operators';
import { AuthByCodeResponse } from '@scpc/dto/auth-code-response';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { getVisitorId } from '@scpc/utils/fingerprint';
import { SearchResultItem } from '@scpc/dto/search.result';
import { Promotion } from '@scpc/dto/promotion';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { SCodeVoucher } from '@scpc/dto/scode.voucher';

@Injectable({ providedIn: 'root' })
export class ScpService {
  private visitorId: string = null;

  constructor(
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    private readonly configService: ConfigService,
  ) {
  }

  private static getDefaultPath_v_1_0(): string {
    return '/scp/api/1.0/';
  }

  private static getDefaultPath_v_1_2(): string {
    return '/scp/api/1.2/';
  }

  private static getDefaultPath_v_1_3(): string {
    return '/scp/api/1.3/';
  }

  public checkUsernameStatus(username: string): Observable<UsernameStatus> {
    return this.http.get<UsernameStatus>(
      ScpService.getDefaultPath_v_1_2() + `sign-up/check-username-status?username=${encodeURIComponent(username)}`,
    );
  }

  public forgotPasswordSendOTP(phone: string, urlToMinify: string): Observable<void> {
    return this.http.post<void>(ScpService.getDefaultPath_v_1_0() + 'forgot-password/otp', {
      phone,
      urlToMinify,
    });
  }

  public forgotPasswordVerifyOTP(phone: string, otp: string): Observable<void> {
    return this.http.put<void>(ScpService.getDefaultPath_v_1_0() + 'forgot-password/otp', {
      phone,
      otp,
    });
  }

  public forgotPasswordChangePassword(phone: string, password: string): Observable<void> {
    return this.http.post<void>(ScpService.getDefaultPath_v_1_2() + 'forgot-password/change-password', {
      phone,
      newPassword: password,
      confirmPassword: password,
    });
  }

  public getCustomer(): Observable<Customer> {
    const url: string = ScpService.getDefaultPath_v_1_0() + `customers/${this.storage.customerId}`;
    return this.http.get<Customer>(url);
  }

  public getProfile(): Observable<Profile> {
    const url: string = ScpService.getDefaultPath_v_1_0() + `customers/${this.storage.customerId}/profile`;
    return this.http.get<Profile>(url);
  }

  public getCustomerIdentifier(id: string): Observable<string> {
    const url: string = ScpService.getDefaultPath_v_1_0() + `customers/${id}/identifier`;
    return this.http.get<string>(url);
  }

  public getAtmDepositSettings(): Observable<BanksDepositSettings> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/atm';
    return this.http.get<BanksDepositSettings>(url);
  }

  public getCardsDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/cards/limits';
    return this.http.get<DepositLimit>(url);
  }

  public getZapperDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/zapper/limits';
    return this.http.get<DepositLimit>(url);
  }

  public getApplePayDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/apple-pay/limits';
    return this.http.get<DepositLimit>(url);
  }

  public getSCodeDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/scode/limits';
    return this.http.get<DepositLimit>(url);
  }

  public depositByCardTdsLookup(card: FullCardDetails, amount: Money, siteUrl: string): Observable<CardTransaction> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/cards/lookup';
    return this.http.post<CardTransaction>(url, { card, amount, siteUrl });
  }

  public depositByDirectCardTdsLookup(
    card: FullCardDetails,
    amount: Money,
    siteUrl: string,
  ): Observable<DirectCardTransaction> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/direct-cards/lookup';
    return this.http.post<DirectCardTransaction>(url, { card, amount, siteUrl });
  }

  public depositByCardTdsAuthenticateAndSale(reference: string): Observable<DepositResult> {
    const url: string =
      this.pathWithCustomer_1_0() + `wallet/deposits/cards/${reference}/authenticate-and-sale`;
    return this.http.post<DepositResult>(url, {});
  }

  public getOzowDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/ozow/limits';
    return this.http.get<DepositLimit>(url);
  }

  public startOzowTransaction(amount: Money, redirectUrl: string): Observable<OzowTransaction> {
    const url: string = this.pathWithCustomer_1_1() + 'wallet/deposits/ozow/transactions';
    return this.http.post<OzowTransaction>(url, { amount, redirectUrl });
  }

  public getOzowCapitecPayDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/ozow-capitec-pay/limits';
    return this.http.get<DepositLimit>(url);
  }

  public startOzowCapitecPayTransaction(amount: Money, redirectUrl: string): Observable<OzowTransaction> {
    const url: string = this.pathWithCustomer_1_1() + 'wallet/deposits/ozow-capitec-pay/transactions';
    return this.http.post<OzowTransaction>(url, { amount, redirectUrl });
  }

  public depositByCreditTransferToken(token: string): Observable<DepositResult> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/credit-transfer-token';
    return this.http.post<DepositResult>(url, { tokenId: token });
  }

  public getCreditTransferTokens(): Observable<CreditTransferToken[]> {
    const url: string = this.pathWithCustomer_1_0() + 'credit-transfer-tokens';
    return this.http.get<CreditTransferToken[]>(url);
  }

  public startZapperTransaction(amount: Money): Observable<ZapperTransaction> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/zapper/transactions';
    return this.http.post<ZapperTransaction>(url, { amount });
  }

  public getZapperTransaction(id: string): Observable<ZapperTransaction> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/deposits/zapper/transactions/${id}`;
    return this.http.get<ZapperTransaction>(url);
  }

  public getSnapScanTransaction(id: string): Observable<SnapScanTransaction> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/deposits/snap-scan/transactions/${id}`;
    return this.http.get<SnapScanTransaction>(url);
  }

  public getDepositLimit(depositType: DepositType): Observable<DepositLimit> {
    if (DepositType.OZOW === depositType) {
      return this.getOzowDepositLimit();
    } else if (DepositType.OZOW_CAPITEC_PAY === depositType) {
      return this.getOzowCapitecPayDepositLimit();
    } else if (DepositType.SNAP_SCAN === depositType) {
      return this.getSnapScanDepositLimit();
    } else if (DepositType.CREDIT_OR_DEBIT_CARD === depositType) {
      return this.getCardsDepositLimit();
    } else if (DepositType.ZAPPER === depositType) {
      return this.getZapperDepositLimit();
    } else if (DepositType.APPLE_PAY === depositType) {
      return this.getApplePayDepositLimit();
    } else if (DepositType.SCODE === depositType) {
      return this.getSCodeDepositLimit();
    }
    return of({} as DepositLimit);
  }

  public getSnapScanDepositLimit(): Observable<DepositLimit> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/snap-scan/limits';
    return this.http.get<DepositLimit>(url);
  }

  public startSnapScanTransaction(amount: Money, siteUrl: string): Observable<SnapScanTransaction> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/snap-scan/transactions';
    return this.http.post<SnapScanTransaction>(url, { amount, siteUrl });
  }

  public getBanksDepositSettings(): Observable<BanksDepositSettings> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/banks';
    return this.http.get<BanksDepositSettings>(url);
  }

  public getEftSettings(): Observable<BanksDepositSettings> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/eft';
    return this.http.get<BanksDepositSettings>(url);
  }

  public depositByOneVoucher(pin: string): Observable<DepositResult> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/1-voucher/transactions';
    return this.http.post<DepositResult>(url, { pin });
  }

  public depositByOttVoucher(pin: string): Observable<DepositResult> {
    const url: string = this.pathWithCustomer_1_1() + 'wallet/deposits/ott-voucher/transactions';
    return this.http.post<DepositResult>(url, { pin });
  }

  public depositByBluVoucher(pin: string): Observable<DepositResult> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/deposits/blu-voucher/transactions';
    return this.http.post<DepositResult>(url, { pin });
  }

  public depositByKazang(pin: string): Observable<DepositResult> {
    const url: string = this.pathWithCustomer_1_1() + 'wallet/deposits/kazang/transactions';
    return this.http.post<DepositResult>(url, { pin });
  }

  public depositByACoinVoucher(pin: string): Observable<DepositResult> {
    const url: string = this.pathWithCustomer_1_1() + 'wallet/deposits/acoin/transactions';
    return this.http.post<DepositResult>(url, { pin });
  }

  public getBonusOffers(
    page: number,
    status?: BonusProgramOfferStatus[],
    ignoreProgress: boolean = false,
    excludeTerms: boolean = false,
    categories?: BonusProgramCategory[],
    wagerTypes?: WagerType[],
    withoutConditions?: boolean,
  ): Observable<Page<BonusProgramOffer>> {
    let url: string = this.pathWithCustomer_1_1() + `bonus-programs-offers?page=${page}&pageSize=20&excludeTerms=${excludeTerms}`;
    if (status) {
      url += status.map((s: BonusProgramOfferStatus): string => '&status=' + s).join('');
    }
    if (categories) {
      url += categories.map((c: BonusProgramCategory): string => '&category=' + c).join('');
    }
    if (wagerTypes) {
      url += wagerTypes.map((w: WagerType) => '&wagerTypes=' + w).join('');
    }
    if (withoutConditions) {
      url += '&withoutConditions=true';
    }
    if (ignoreProgress) {
      return this.http.get<Page<BonusProgramOffer>>(url, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      });
    } else {
      return this.http.get<Page<BonusProgramOffer>>(url);
    }
  }

  public getBalance(): Observable<Balance> {
    return this.http.get<Balance>(this.pathWithCustomer_1_0() + 'wallet/balance');
  }

  public getBonusOffersStats(): Observable<BonusProgramOffersStats> {
    return this.http.get<BonusProgramOffersStats>(this.pathWithCustomer_1_0() + 'bonus-programs-offers/stats', {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  public getBonusOffer(bonusProgramOfferId: number | string, email?: string, token?: string): Observable<BonusProgramOffer> {
    const url: string = this.pathWithCustomer_1_0() + `bonus-programs-offers/${bonusProgramOfferId}`;
    return this.http.get<BonusProgramOffer>(url + (email && token ? `?email=${email}&token=${token}` : ''));
  }

  public acceptBonusOffer(bonusProgramOfferId: number): Observable<BonusProgramOffer> {
    const url: string = this.pathWithCustomer_1_0() + `bonus-programs-offers/${bonusProgramOfferId}/accept`;
    return this.http.patch<BonusProgramOffer>(url, {});
  }

  public rejectBonusOffer(bonusProgramOfferId: number): Observable<BonusProgramOffer> {
    const url: string = this.pathWithCustomer_1_0() + `bonus-programs-offers/${bonusProgramOfferId}/reject`;
    return this.http.patch<BonusProgramOffer>(url, {});
  }

  public getBonusProgramReferrals(bonusProgramOfferId: string): Observable<Referral[]> {
    const url: string =
      this.pathWithCustomer_1_0() + `bonus-programs-offers/${bonusProgramOfferId}/referrals`;
    return this.http.get<Referral[]>(url);
  }

  public getBonusProgramReferral(bonusProgramOfferId: string, referralId: string): Observable<Referral> {
    const url: string =
      this.pathWithCustomer_1_0() + `bonus-programs-offers/${bonusProgramOfferId}/referrals/${referralId}`;
    return this.http.get<Referral>(url);
  }

  public inviteReferral(bonusProgramOfferId: string, phone: string, link: string): Observable<void> {
    const url: string =
      this.pathWithCustomer_1_0() + `bonus-programs-offers/${bonusProgramOfferId}/referrals`;
    return this.http.post<void>(url, { phone, link });
  }

  public getFacebookBotUrl(location: string): Observable<{ url: string }> {
    const link = `${location}?referrer=${this.storage.customerId}&source=FACEBOOK`;
    const url: string = this.pathWithCustomer_1_0() + `bonus-programs-offers/facebook-bot-url?url=${link}`;
    return this.http.get<{ url: string }>(url);
  }

  public getDepositConfiguration(): Observable<DepositConfiguration> {
    const url: string = this.pathWithCustomer_1_1() + 'wallet/deposits';
    return this.http.get<DepositConfiguration>(url);
  }

  public getWithdrawalConfiguration(stats: boolean = false): Observable<WithdrawalConfiguration> {
    const url: string = this.pathWithCustomer_1_1() + `wallet/withdrawals?stats=${stats}`;
    return this.http.get<WithdrawalConfiguration>(url);
  }

  public getStandardBankWithdrawalStatistics(): Observable<WithdrawalStatistics> {
    const url: string =
      this.pathWithCustomer_1_0() + 'wallet/withdrawals/standard-bank-instant-money/statistics';
    return this.http.get<WithdrawalStatistics>(url);
  }

  public getOneVoucherWithdrawalStatistics(): Observable<WithdrawalStatistics> {
    const url: string =
      this.pathWithCustomer_1_0() + 'wallet/withdrawals/1-voucher/statistics';
    return this.http.get<WithdrawalStatistics>(url);
  }

  public getWithdrawalRequests(): Observable<WithdrawalRequest[]> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/requests';
    return this.http.get<WithdrawalRequest[]>(url);
  }

  public getWithdrawalRequestsById(id: string): Observable<WithdrawalRequest> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/withdrawals/requests/${id}`;
    return this.http.get<WithdrawalRequest>(url);
  }

  public rejectWithdrawalRequest(id: string): Observable<WithdrawalRequest> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/withdrawals/requests/${id}/reject`;
    return this.http.put<WithdrawalRequest>(url, {});
  }

  public getStandardBankVoucher(id: string): Observable<Voucher> {
    const url: string =
      this.pathWithCustomer_1_0() + `wallet/withdrawals/requests/${id}/standard-bank-instant-money/voucher`;
    return this.http.get<Voucher>(url);
  }

  public getOneVoucher(id: string): Observable<Voucher> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/withdrawals/requests/${id}/1-voucher/voucher`;
    return this.http.get<Voucher>(url);
  }

  public resendStandardBankVoucher(id: string): Observable<void> {
    const url: string =
      this.pathWithCustomer_1_0() +
      `wallet/withdrawals/requests/${id}/standard-bank-instant-money/resend/voucher`;
    return this.http.put<void>(url, {});
  }

  public resendStandardBankVoucherPin(id: string): Observable<void> {
    const url: string =
      this.pathWithCustomer_1_0() +
      `wallet/withdrawals/requests/${id}/standard-bank-instant-money/resend/pin`;
    return this.http.put<void>(url, {});
  }

  public resendOneVoucher(id: string): Observable<void> {
    const url: string =
      this.pathWithCustomer_1_0() +
      `wallet/withdrawals/requests/${id}/1-voucher/resend/voucher`;
    return this.http.put<void>(url, {});
  }

  public creatCard(request: CreateCardRequest): Observable<Card> {
    const url: string = this.pathWithCustomer_1_0() + 'cards';
    return this.http.post<Card>(url, request);
  }

  public getCards(): Observable<Card[]> {
    const url: string = this.pathWithCustomer_1_0() + 'cards';
    return this.http.get<Card[]>(url);
  }

  public getAvailableDepositBonusProgramsOffers(type: DepositType): Observable<DepositBonusProgramOffer[]> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/deposits/bonus-programs-offers?type=${type}`;
    return this.http.get<DepositBonusProgramOffer[]>(url);
  }

  public getCard(id: string): Observable<Card> {
    const url: string = this.pathWithCustomer_1_0() + `cards/${id}`;
    return this.http.get<Card>(url);
  }

  public getCardTransaction(id: string): Observable<CardTransaction> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/deposits/cards/${id}`;
    return this.http.get<CardTransaction>(url);
  }

  public getDirectCardsTransaction(id: string): Observable<DirectCardTransaction> {
    const url: string = this.pathWithCustomer_1_0() + `wallet/deposits/direct-cards/transactions/${id}`;
    return this.http.get<CardTransaction>(url);
  }

  public deleteCard(id: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + `cards/${id}`;
    return this.http.delete<void>(url);
  }

  public getSocialAccounts(): Observable<SimpleUserAccount[]> {
    const url: string = this.pathWithCustomer_1_0() + 'social-accounts';
    return this.http.get<SimpleUserAccount[]>(url);
  }

  public createSocialAccounts(code: string, redirectUrl: string): Observable<AuthByCodeResponse> {
    const url: string = this.pathWithCustomer_1_0() + 'social-accounts';
    return this.http.post<AuthByCodeResponse>(url, { code, url: redirectUrl });
  }

  public getSignInProviders(redirectUrl: string): Observable<{ url: string, provider: string }[]> {
    const url: string = ScpService.getDefaultPath_v_1_0() + `sign-in/providers?url=${encodeURIComponent(redirectUrl)}`;
    return this.http.get<{ url: string, provider: string }[]>(url);
  }

  public signInByCode(code: string,
                      url: string,
                      source: string,
                      referrer?: Referrer,
                      googleAnalyticsData?: GoogleAnalytics): Observable<{ token: AccessToken; action: string }> {
    const path: string = ScpService.getDefaultPath_v_1_0() + 'oauth2/code';
    return from(this.getCurrentVisitorId()).pipe(
      switchMap((fingerprint) => this.http.post<{ token: AccessToken; action: string }>(path, {
        code,
        url,
        fingerprint,
        referrer,
        googleAnalyticsData,
        source,
      })),
    );
  }

  public getKycSettings(): Observable<KycSettings> {
    const url: string = this.pathWithCustomer_1_0() + 'kyc';
    return this.http.get<KycSettings>(url);
  }

  public getVerificationForm(resident: boolean): Observable<{ url: string }> {
    const url: string = this.pathWithCustomer_1_0() + `kyc/documents/form?formType=${resident ? 'SA_FORM' : 'NOT_RESIDENT_FORM'}`;
    return this.http.get<{ url: string }>(url);
  }

  public getEkycOptions(governmentId: string): Observable<string[]> {
    const url: string = ScpService.getDefaultPath_v_1_0() + 'e-kyc/options?governmentId=' + governmentId;
    return this.http.get<string[]>(url);
  }

  public verifyEKYCOption(governmentId: string, option: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'e-kyc/options';
    return this.http.put<void>(url, { governmentId, option });
  }

  public changeGovernmentId(governmentId: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'change-government-id';
    return this.http.patch<void>(url, { governmentId });
  }

  public checkGovernmentId(governmentId: string): Observable<{ status: string }> {
    const url: string =
      ScpService.getDefaultPath_v_1_0() + 'sign-up/check-government-id-status?governmentId=' + governmentId;
    return this.http.get<{ status: string }>(url);
  }

  public sendWithdrawalRequestStandardBankInstantMoney(amount: Money): Observable<{ isProcessed: boolean }> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/standard-bank-instant-money';
    return this.http.post<{ isProcessed: boolean }>(url, { amount });
  }

  public sendWithdrawalRequestOneVoucher(amount: Money): Observable<{ isProcessed: boolean }> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/1-voucher';
    return this.http.post<{ isProcessed: boolean }>(url, { amount });
  }

  public sendWithdrawalRequestOneVoucherIsAllowed(amount: Money): Observable<WithdrawalFee> {
    const url: string =
      this.pathWithCustomer_1_0() + 'wallet/withdrawals/1-voucher/is-allowed';
    return this.http.post<WithdrawalFee>(url, { amount });
  }


  public sendWithdrawalRequestStandardBankIsAllowed(amount: Money): Observable<WithdrawalFee> {
    const url: string =
      this.pathWithCustomer_1_0() + 'wallet/withdrawals/standard-bank-instant-money/is-allowed';
    return this.http.post<WithdrawalFee>(url, { amount });
  }

  public getCustomerBanks(): Observable<CustomerBank[]> {
    const url: string = this.pathWithCustomer_1_0() + 'banks';
    return this.http.get<CustomerBank[]>(url);
  }

  public deleteCustomerBanks(bankId: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + `banks/${bankId}`;
    return this.http.delete<void>(url);
  }

  public getWithdrawalBanks(): Observable<OperatorBank[]> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/send-to-my-bank/banks';
    return this.http.get<OperatorBank[]>(url);
  }

  public getBankVerification(): Observable<BankVerification> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/send-to-my-bank/mode';
    return this.http.get<BankVerification>(url);
  }

  public getEftBankWithdrawalStatistics(): Observable<WithdrawalStatistics> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/send-to-my-bank/statistics';
    return this.http.get<WithdrawalStatistics>(url);
  }

  public createCustomerBank(data: OperatorBank): Observable<CustomerBank> {
    const url: string = this.pathWithCustomer_1_0() + 'banks';
    return this.http.post<CustomerBank>(url, data);
  }

  public sendWithdrawalRequestBankIsAllowed(amount: Money): Observable<WithdrawalFee> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/send-to-my-bank/is-allowed';
    return this.http.post<WithdrawalFee>(url, { amount });
  }

  public sendWithdrawalRequestBank(bankId: string, amount: Money): Observable<WithdrawalFee> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/send-to-my-bank';
    return this.http.post<WithdrawalFee>(url, { amount, bankId });
  }

  public updateCustomerBank(bankId: string, formData: FormData): Observable<CustomerBank> {
    const url: string = this.pathWithCustomer_1_0() + 'banks/' + bankId;
    return this.http.put<CustomerBank>(url, formData);
  }

  public getCustomerBank(bankId: string): Observable<CustomerBank> {
    const url: string = this.pathWithCustomer_1_0() + 'banks/' + bankId;
    return this.http.get<CustomerBank>(url);
  }

  public changePhoneSendOTP(phone: string, urlToMinify: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'change-phone/otp';
    return this.http.post<void>(url, { phone, urlToMinify });
  }

  public changePhoneVerifyOTP(phone: string, otp: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'change-phone/otp';
    return this.http.put<void>(url, { phone, otp });
  }

  public confirmPhoneSendOTP(urlToMinify: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + `verify-phone/otp?url=${urlToMinify}`;
    return this.http.get<void>(url);
  }

  public confirmPhoneVerifyOTP(otp: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'verify-phone/otp';
    return this.http.put<void>(url, { otp });
  }

  public signUp(
    phone: string,
    password: string,
    source: string,
    governmentId?: string,
    referrer?: Referrer,
    googleAnalyticsData?: GoogleAnalytics,
  ): Observable<void> {
    return from(this.getCurrentVisitorId()).pipe(
      switchMap((fingerprint) => {
        const url: string = ScpService.getDefaultPath_v_1_3() + 'sign-up';
        return this.http.post<void>(url, {
          phone,
          password,
          governmentId: governmentId || '',
          referrer,
          confirmPassword: password,
          googleAnalyticsData,
          fingerprint,
          source,
        });
      }),
    );
  }

  public signUpSendOTP(phone: string, urlToMinify: string): Observable<void> {
    const url: string = ScpService.getDefaultPath_v_1_0() + 'sign-up/otp';
    return this.http.post<void>(url, { phone, urlToMinify });
  }

  public signUpVerifyOTP(phone: string, otp: string): Observable<void> {
    const url: string = ScpService.getDefaultPath_v_1_0() + 'sign-up/otp';
    return this.http.put<void>(url, { phone, otp });
  }

  public changePhone(phone: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'change-phone';
    return this.http.patch<void>(url, { phone });
  }

  public changeEmail(email: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'email';
    return this.http.patch<void>(url, { email });
  }

  public changeSourceOfFunds(source: string): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'source-of-funds';
    return this.http.patch<void>(url, { source });
  }

  public getWithdrawalsCTTStatistics(): Observable<WithdrawalStatistics> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/credit-transfer-token/statistics';
    return this.http.get<WithdrawalStatistics>(url);
  }

  public sendWithdrawalsCTTIsAllowed(amount: Money): Observable<WithdrawalFee> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/credit-transfer-token/is-allowed';
    return this.http.post<WithdrawalFee>(url, { amount });
  }

  public sendWithdrawalsCTTInstantMoney(amount: Money): Observable<WithdrawalFee> {
    const url: string = this.pathWithCustomer_1_0() + 'wallet/withdrawals/credit-transfer-token';
    return this.http.post<WithdrawalFee>(url, { amount });
  }

  public getCTTokens(): Observable<CreditTransferToken[]> {
    const url: string = this.pathWithCustomer_1_0() + 'credit-transfer-tokens';
    return this.http.get<CreditTransferToken[]>(url);
  }

  public getCTToken(id: string): Observable<CreditTransferToken> {
    const url = ScpService.getDefaultPath_v_1_0() + `credit-transfer-tokens/${id}`;
    return this.http.get<CreditTransferToken>(url);
  }

  public uploadKyc(formData: FormData): Observable<void> {
    const url: string = this.pathWithCustomer_1_0() + 'kyc/documents';
    return this.http.post<void>(url, formData);
  }

  public getTransactions(
    page: number,
    pageSize: number,
    types?: string[],
    fromDate?: Date,
    toDate?: Date,
  ): Observable<Page<BusinessTransaction>> {
    let query = '';
    if (types?.length) {
      for (const type of types) {
        query += '&type=' + type;
      }
    }
    if (fromDate) {
      query += '&from=' + new Date(fromDate).getTime();
    }
    if (toDate) {
      query += '&to=' + new Date(toDate).getTime();
    }
    const url: string =
      this.pathWithCustomer_1_0() + `transactions?page=${page}&pageSize=${pageSize}` + query;
    return this.http.get<Page<BusinessTransaction>>(url);
  }

  public changePassword(currentPassword: string, newPassword: string, confirmPassword: string): Observable<void> {
    return this.http.patch<void>(this.pathWithCustomer_1_2() + 'change-password', {
      currentPassword,
      newPassword,
      confirmPassword,
    });
  }

  public getWagers(
    page: number,
    pageSize: number,
    statuses?: string[],
    fromDate?: Date | number,
    toDate?: Date | number,
    type?: string,
    products?: string[],
  ): Observable<Page<Wager>> {
    let query = '';
    if (statuses?.length) {
      for (const status of statuses) {
        query += '&status=' + status;
      }
    }
    if (fromDate) {
      query += '&from=' + new Date(fromDate).getTime();
    }
    if (toDate) {
      query += '&to=' + new Date(toDate).getTime();
    }
    if (type) {
      query += '&type=' + type;
    }
    if (products) {
      for (const product of products) {
        query += '&product=' + product;
      }
    }
    const url: string = this.pathWithCustomer_1_0() + `wagers?page=${page}&pageSize=${pageSize}` + query;
    return this.http.get<Page<Wager>>(url);
  }

  public getWager(wagerId: string): Observable<Wager> {
    const url: string = this.pathWithCustomer_1_0() + `wagers/${wagerId}`;
    return this.http.get<Wager>(url);
  }

  public getSubscriptions(
    page: number,
    pageSize: number,
    statuses?: string[],
    fromDate?: Date | number,
    toDate?: Date | number,
  ): Observable<Page<Subscription>> {
    let query = '';
    if (statuses?.length) {
      for (const status of statuses) {
        query += '&status=' + status;
      }
    }
    if (fromDate) {
      query += '&from=' + new Date(fromDate).getTime();
    }
    if (toDate) {
      query += '&to=' + new Date(toDate).getTime();
    }
    const url: string = this.pathWithCustomer_1_0() + `subscriptions?page=${page}&pageSize=${pageSize}` + query;
    return this.http.get<Page<Subscription>>(url);
  }

  public getSubscription(subscriptionsId: string): Observable<Subscription> {
    const url: string = this.pathWithCustomer_1_0() + `subscriptions/${subscriptionsId}`;
    return this.http.get<Subscription>(url);
  }

  public cancelSubscription(subscriptionsId: string): Observable<Subscription> {
    const url: string = this.pathWithCustomer_1_0() + `subscriptions/${subscriptionsId}/cancel`;
    return this.http.patch<Subscription>(url, {});
  }

  public cancelWager(wagerId: string): Observable<Wager> {
    const url: string = this.pathWithCustomer_1_0() + `wagers/${wagerId}/cancel`;
    return this.http.patch<Wager>(url, {});
  }

  public getNotifications(): Observable<{ method: string; enabled: boolean }[]> {
    const url: string = this.pathWithCustomer_1_0() + `settings/notifications`;
    return this.http.get<{ method: string; enabled: boolean }[]>(url);
  }

  public changeNotificationMethod(method: string, enabled: boolean): Observable<{ method: string; enabled: boolean }> {
    const url: string = this.pathWithCustomer_1_0() + `settings/notifications`;
    return this.http.put<{ method: string; enabled: boolean }>(
      url,
      { method, enabled },
      {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      },
    );
  }

  public search(product: string, value: string): Observable<SearchResultItem[]> {
    let query = '';
    if (product) {
      query += `&product=${product}`;
    }
    return this.http.get<SearchResultItem[]>(ScpService.getDefaultPath_v_1_0() + `search/events` + `?query=${value}` + query);
  }

  public getPromotions(page: number): Observable<Page<Promotion>> {
    return this.http.get<Page<Promotion>>((this.storage.customerId
        ? this.pathWithCustomer_1_0()
        : ScpService.getDefaultPath_v_1_0())
      + `promotions?page=${page}&pageSize=20`);
  }

  public getPromotionsPreview(): Observable<Page<Promotion>> {
    return this.http.get<Promotion[]>(ScpService.getDefaultPath_v_1_0() + `promotions/preview`)
      .pipe(/* istanbul ignore next */map(/* istanbul ignore next */(promotions: Promotion[]) => ({
        page: 0,
        pageSize: promotions.length,
        items: promotions,
        totalPages: promotions.length > 0 ? 1 : 0,
        totalItems: promotions.length,
      })));
  }

  public getPromotion(id: string, fill: boolean): Observable<Promotion> {
    return this.http.get<Promotion>((this.storage.customerId
        ? this.pathWithCustomer_1_0()
        : ScpService.getDefaultPath_v_1_0())
      + `promotions/${id}` + (fill ? '?fill=true' : ''));
  }

  public getInboxMessages(): Observable<InboxMessage[]> {
    return this.http.get<InboxMessage[]>(this.pathWithCustomer_1_0() + 'inbox/messages');
  }

  public isFirstDeposit(excludeType: DepositType, excludeReference: string): Observable<boolean> {
    return this.http.get<boolean>(this.pathWithCustomer_1_0() + `wallet/deposits/is-first-deposit?type=${excludeType}&reference=${excludeReference}`);
  }

  public getURLForRedirectAfterFirstDeposit(): Observable<string> {
    return this.http.get<string>(this.pathWithCustomer_1_0() + `wallet/deposits/url-for-redirect-after-first-deposit`);
  }

  public createApplePayCheckout(amount: Money): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(this.pathWithCustomer_1_0() + 'wallet/deposits/apple-pay/checkouts', {
      amount,
    });
  }

  public processApplePayCheckout(resourcePath: string): Observable<{
    first: boolean,
    amount: Money
  }> {
    return this.http.get<{
      first: boolean,
      amount: Money
    }>(this.pathWithCustomer_1_0() + `wallet/deposits/apple-pay/checkouts?resourcePath=${resourcePath}`);
  }

  public getSCodeVoucher(): Observable<SCodeVoucher> {
    return this.http.get<SCodeVoucher>(this.pathWithCustomer_1_0() + 'wallet/deposits/scode/voucher');

  }

  private pathWithCustomer_1_0(): string {
    return `/scp/api/1.0/customers/${this.storage.customerId}/`;
  }

  private pathWithCustomer_1_1(): string {
    return `/scp/api/1.1/customers/${this.storage.customerId}/`;
  }

  private pathWithCustomer_1_2(): string {
    return `/scp/api/1.2/customers/${this.storage.customerId}/`;
  }

  private async getCurrentVisitorId(): Promise<string> {
    try {
      if (!this.visitorId) {
        this.visitorId = await getVisitorId(this.configService);
      }
      return this.visitorId;
    } catch (e) /* istanbul ignore next */ {
      return '';
    }
  }

}
