<header class='scp-header' [class.unauthorized]='!isAuthorized'>
  @if (!menuButtonHidden) {
    <button mat-icon-button
            (click)='toggleSidenav.next()'
            aria-label='Menu'
            class='scp-header__menu'>
      <img src='/assets/images/svg/menu.svg' alt='' height='24' width='24' loading='lazy' />
    </button>
  }
  <a routerLink='/' [attr.aria-label]='siteName' class='scp-header__logo'>
    @if (normalizedSiteName) {
      <img style='display: block'
           src='/assets/images/svg/{{ normalizedSiteName }}.svg'
           alt=''
           width='100%'
      />
    }
  </a>
  <div tourAnchor='default.step-1'>
    <button class='scp-header__tours' mat-icon-button [matMenuTriggerFor]='scpToursMenu'
            (click)='hideTour.emit()'
            [attr.aria-label]="'TITLE.TOURS' | translate">
      <img src='/assets/images/svg/tours.svg' alt='' height='24' width='24' loading='lazy' />
    </button>
    <mat-menu #scpToursMenu='matMenu'>
      <ng-template matMenuContent>
        @if (!toursDisabled) {
          <div (click)='$event.preventDefault(); $event.stopPropagation();' class='mat-menu-panel-title'>
            {{ 'Let us show you how to…' | translate }}
          </div>
          <mat-divider></mat-divider>
          @for (t of tours; track t.name) {
            <div mat-menu-item (click)='tour.emit(t)'>{{ 'TOUR.N.' + t.name | translate }}</div>
            <mat-divider></mat-divider>
          }
        }
        <ng-container>
          <a mat-menu-item class='chat' routerLink='/faq'>
            {{ 'TITLE.FAQ_HEADER' | translate }}
          </a>
        </ng-container>
      </ng-template>
    </mat-menu>
  </div>
  <div class='scp-header__products-wrapper'>
    <scp-products type='desktop'></scp-products>
    @if (balance) {
      <div class='scp-header__balance-responsive'>
        <div>
          <div>{{ 'Balance ' | translate }}</div>
          <span class='scp-header__balance-responsive__cash'> {{ balance.TOTAL_CASH[0] | formatMoney }}</span>
        </div>
        <div>
          <div>{{ 'Bonus ' | translate }}</div>
          <span class='scp-header__balance-responsive__bonus'> {{ balance.BONUS[0] | formatMoney }}</span>
        </div>
      </div>
    }
  </div>
  @if (!chats.length) {
    <div></div>
  }
  @if (isAuthorized) {
    <button mat-icon-button class='scp-header__inbox'
            #scpInbox
            [matMenuTriggerFor]='scpInboxMenu'
            aria-label='Inbox'
            (click)='showInbox()'>
      <span #bell class='scp-header__bell is-animating'
            [class.scp-header__bell__has_messages]='inboxService.hasMessages | async'>
        <svg width='24' height='24' viewBox='0 -2 16 22'>
          <g>
            <path class='scp-header__bell__bow' stroke='var(&#45;&#45;scp-theme-color-01)' stroke-width='1.7'
                  d='M15,8.5 C15,5.43 12.86,2.86 10,2.18 L10,1.5 C10,0.671572875 9.32842712,0 8.5,0 C7.67157288,0 7,0.671572875 7,1.5 L7,2.18 C4.13,2.86 2,5.43 2,8.5 L2,14 L0,16 L0,17 L17,17 L17,16 L15,14 L15,8.5 Z' />
            <path class='scp-header__bell__clapper'
                  d='M2.5,2 C2.64,2 2.77,2 2.9,1.96 C3.55,1.82 4.09,1.38 4.34,0.78 C4.44,0.54 4.5,0.27 4.5,0 L0.5,0 C0.5,1.1045695 1.3954305,2 2.5,2 L2.5,2 Z' />
          </g>
        </svg>
      </span>
    </button>
    <mat-menu #scpInboxMenu class='scp-inbox'>
      <ng-template matMenuContent>
        @if (loading) {
          <div class='scp-inbox__loader scp-header__inbox__message__empty'>
            <img src='/assets/images/svg/loader.svg' alt='' height='42' width='42'
                 style='animation: spin 2s linear infinite;'>
          </div>
        } @else {
          @if (!loading && !messages?.length) {
            <div class='scp-header__inbox__message__empty'>
              <img src='/assets/images/svg/no-messages.svg' alt='' height='52' width='41'>
              <div>{{ 'Nothing is here' | translate }}</div>
            </div>
          } @else if (!loading && messages?.length) {
            <div (click)='$event.preventDefault(); $event.stopPropagation();' class='mat-menu-panel-title small'>
              {{ 'Available Promos and Bonuses:' | translate }}
            </div>
            <mat-divider></mat-divider>
          }
          @for (message of messages; track message; let last = $last) {
            @switch (message.type) {
              @case ('BONUS_PROGRAM_OFFER') {
                <a mat-menu-item class='scp-header__inbox__message' (click)='showInboxMessage(message)'>
                  <img src='/assets/images/svg/bonus-offer.svg' alt='' height='32' width='32' loading='lazy' />
                  <div class='scp-header__inbox__message__content'>
                    <div class='scp-header__inbox__message__content__title'>
                      {{ message.bonusProgramOffer.bonusProgram.name }}
                    </div>
                    @if (message.expiredAt) {
                      <div
                        class='scp-header__inbox__message__content__expired_at'>{{ 'EXPIRE_DATE' | translate: { date: message.expiredAt | date: 'dd-MM-yyyy HH:mm' } }}
                      </div>
                    }
                  </div>
                </a>
              }
              @case ('PROMOTION') {
                <a mat-menu-item class='scp-header__inbox__message' (click)='showInboxMessage(message)'>
                  <img src='/assets/images/svg/bonus-offer.svg' alt='' height='32' width='32' loading='lazy' />
                  <div class='scp-header__inbox__message__content'>
                    <div class='scp-header__inbox__message__content__title'>
                      {{ message.promotion.name }}
                    </div>
                    @if (message.expiredAt) {
                      <div
                        class='scp-header__inbox__message__content__expired_at'>{{ 'EXPIRE_DATE' | translate: { date: message.expiredAt | date: 'dd-MM-yyyy HH:mm' } }}
                      </div>
                    }
                  </div>
                </a>
              }
            }
            @if (!last) {
              <mat-divider></mat-divider>
            }
          }
        }
      </ng-template>
    </mat-menu>
  }
  @if (chats.length) {
    <div style='display: flex'>
      <button mat-icon-button
              (click)='showSupportChat()'
              class='scp-header__chats'
              [class.scp-header__unread]='zendeskUnreadMessages'
              [matMenuTriggerFor]='chats.length > 1 ? scpChatsMenu: null'
              [attr.aria-label]="'BUTTON.SUPPORT_CHATS' | translate">
        <img src='/assets/images/svg/chats.svg' alt='' height='24' width='24' loading='lazy' />
        @if (zendeskUnreadMessages) {
          <span class='scp-header__chat_unread'></span>
        }
      </button>
      <mat-menu #scpChatsMenu='matMenu'>
        <ng-template matMenuContent>
          @for (chat of chats; track chat; let last = $last) {
            <ng-template #other>
              <button class='scp-header__chat' mat-menu-item (click)='showSupportChat(chat)'>
                <img
                  src='/assets/images/svg/{{chat.icon}}.svg'
                  [class]='chat.class'
                  alt='{{ chat.name | translate }}'
                  width='{{chat.width}}'
                  height='{{chat.height}}'
                />
                {{ chat.name | translate }}
              </button>
            </ng-template>
            @if (!last) {
              <mat-divider></mat-divider>
            }
          }
        </ng-template>
      </mat-menu>
    </div>
  }
  <scp-cart-button [visible]='cart'></scp-cart-button>
  @if (isAuthorized) {
    <scp-btn [hasMarginTop]='false'
             class='scp-header__deposit'
             height='36'
             type='1'
             text='TITLE.DEPOSIT'
             url='/account/wallet/deposits'></scp-btn>
  }
  @if (!isAuthorized) {
    <scp-btn
      class='scp-header__sign-in'
      [hasMarginTop]='false'
      height='36'
      type='16'
      text='TITLE.SIGN_IN_OR_SIGN_UP_2'
      url='/sign-in' (click)='rememberLink()'></scp-btn>
  }
</header>
@if (balance) {
  <div class='scp-header__balance'>
    <div>
      {{ 'Balance ' | translate }}<span
      class='scp-header__balance__cash'> {{ balance.TOTAL_CASH[0] | formatMoney }}</span>
    </div>
    <div>
      {{ 'Bonus ' | translate }}<span
      class='scp-header__balance__bonus'> {{ balance.BONUS[0] | formatMoney }}</span>
    </div>
  </div>
}
