import { AuthService } from '@scpc/modules/auth/common';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AccessToken } from '@scpc/dto';
import { StorageService } from './storage.service';
import { parseQuery } from '@scpc/utils/url.utils';
import { FastifyReply } from 'fastify';
import { parseJWT } from '@scpc/utils/jwt.utils';

@Injectable()
export class AuthenticationService implements AuthService {

  private interruptedUrl: string;
  private $refresh: Subject<void> = new Subject<void>();

  private readonly $authorization: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly router: Router,
    @Optional() @Inject('RESPONSE') private readonly response: FastifyReply,
  ) {
  }

  public get authorization(): Observable<boolean> {
    return this.$authorization.asObservable();
  }

  public get refresh(): Observable<void> {
    return this.$refresh;
  }

  public isAuthorized(): Observable<boolean> {
    const isAuthorized = !!this.storageService.accessToken;
    return of(isAuthorized);
  }

  public getAccessToken(): Observable<string> {
    const accessToken: string = this.storageService.accessToken;

    return of(accessToken);
  }

  public refreshToken(): Observable<any> {
    const refreshToken: string = this.storageService.refreshToken;
    const observable: Subject<void> = new Subject();

    this.http
      .post(`/scp/api/1.2/oauth2/token`, {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      })
      .subscribe({
        next: (accessToken: AccessToken) => {
          this.storageService.saveAccessToken(accessToken);
          this.$refresh.next();
          observable.next();
        },
        error: async (error: HttpErrorResponse) => {
          this.storageService.deleteAccessToken();
          if (this.response) {
            this.response.redirect(302, '/sign-in');
          } else {
            this.router.navigateByUrl('/sign-in');
          }
          observable.error(error);
        },
      });

    return observable;
  }

  /* istanbul ignore next */
  public tryToRefreshToken(): Observable<void> {
    try {
      const accessToken: string = this.storageService.accessToken;
      if (accessToken && Number(parseJWT(accessToken).payload.exp) * 1000 <= new Date().getTime() - 10_000) {
        return this.refreshToken();
      }
    } catch (e) { /* empty */
    }
    return of(void 0);
  }

  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  public verifyRefreshToken(req: HttpRequest<any>): boolean {
    return req.url.endsWith('/oauth2/token');
  }

  public skipRequest(req: HttpRequest<any>): boolean {
    return req.url.includes('cms') || req.url.includes('widget_settings');
  }

  public setInterruptedUrl(url: string): void {
    this.interruptedUrl = url;
  }

  public signIn(username: string, password: string, skipNavigation: boolean = false): Observable<void> {
    const observable: Subject<void> = new Subject();
    this.http
      .post<AccessToken>(`/scp/api/1.2/oauth2/token`, {
        username,
        password,
        grant_type: 'password',
      })
      .subscribe({
        next: (accessToken: AccessToken) => {
          this.storageService.saveAccessToken(accessToken);
          observable.next();
          this.$authorization.next(true);
          if (!skipNavigation) {
            this.navigateAfterSignIn();
          }
        },
        error: async (error: HttpErrorResponse) => observable.error(error),
      });
    return observable;
  }

  public signInBySocialProfile(accessToken: AccessToken, customerId?: string, skipNavigation = false): void {
    this.storageService.saveAccessToken(accessToken, customerId);
    this.$authorization.next(true);
    if (!skipNavigation) {
      this.navigateAfterSignIn();
    }
  }

  public signOut(): void {
    const accessToken: string = this.storageService.accessToken;
    const refreshToken: string = this.storageService.refreshToken;
    this.storageService.deleteAccessToken();
    this.storageService.setCustomer(null);
    this.storageService.setBalance(null);
    this.storageService.setWithdrawalConfiguration(null);
    this.storageService.setBonusProgramOffers(null);
    this.$authorization.next(false);
    forkJoin([
      this.http.post<AccessToken>(`/scp/api/1.0/oauth2/revoke`, {
        token: accessToken,
        token_type_hint: 'access_token',
      }, { headers: { Authorization: 'Bearer ' + accessToken } }),
      this.http.post<AccessToken>(`/scp/api/1.0/oauth2/revoke`, {
        token: refreshToken,
        token_type_hint: 'refresh_token',
      }, { headers: { Authorization: 'Bearer ' + accessToken } }),
    ]).subscribe();
  }

  private navigateAfterSignIn() {
    const path = decodeURIComponent(this.interruptedUrl || '/account/profile');
    const parts = path.split('?');
    this.router.navigate([parts[0]], { queryParams: parts[1] ? /* istanbul ignore next */ parseQuery(parts[1]) : {} });
  }

}
